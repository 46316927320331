import React from "react"

export default function Testimonial({ text, who, where }) {
  return (
    <div className="md:max-w-4xl my-8 md:my-32 flex flex-col">
      <p className="font-display italic font-light text-xl md:text-4xl mb-2 md:mb-4">{text} </p>
      <div className="flex flex-col">
        <span className="text-sm font-medium">{who}</span>
        <span className="text-sm blackBlue">{where}</span>
      </div>
    </div>
  )
}
