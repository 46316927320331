import React from "react"

export default function TitleSubtitle({title, sub}) {
  return (
    <div className="mt-6 md:mt-20 flex">
      <div>
        <p className="text-xl max-w-xl mt-0 text-gray-300 mb-4">{sub}</p>
      </div>
    </div>
  )
}
