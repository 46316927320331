import React, { useRef } from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Logo from "./logo"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Reveal from "./reveal"
import { Helmet } from "react-helmet"
import Para from "./Para"
import Paragraph from "./Paragraph"
import Twocols from "./Twocols"
import Testimonial from "./Testimonial"
import TitleSubtitle from "./TitleSubtitle"
import Press from "./Press"
import Introparalong from "./Introparalong"
import Intropara from "./Intropara"
import Imagernd from "./Imagernd"

import Voiceflow from "../components/svg/voiceflow"
import Juicybeast from "../components/svg/juicybeast"
import Gretel from "../components/svg/gretel"
import Pinjour from "../components/svg/pinjour"
import Casper from "../components/svg/casper"
import Panchos from "../components/svg/panchos"
import Bankjoy from "./svg/bankjoy"
import Cg from "./svg/cg"
import Athlete from "./svg/athlete"
import Chimney from "./svg/chimney"
import Casesmenu from "./casesmenu"

const shortcodes = {
  Imagernd,
  Link,
  Intropara,
  Introparalong,
  Reveal,
  Press,
  GatsbyImage,
  Para,
  Paragraph,
  Twocols,
  Testimonial,
  TitleSubtitle,
}
const IMAGE_KEY = "image"

export default function Caseslayout({
  data: { mdx },
  pageContext: { next, previous },
}) {
  const embeddedImagesByKey =
    mdx.frontmatter.embeddedImages &&
    mdx.frontmatter.embeddedImages.reduce((images, image, index) => {
      images[`${IMAGE_KEY}${index + 1}`] = images[
        `${IMAGE_KEY}${index + 1}`
      ] || {
        ...image.childImageSharp,
      }
      return images
    }, {})
  const constraintsRef = useRef(null)
  // const location = {pathname: `/work/${mdx.frontmatter.slug}` }
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Lance / {mdx.frontmatter.title}</title>
        <body className="scrollSmooth no-scrollbar blackTheme" />
      </Helmet>
      <>
     

        
        <div className="mt-24 md:mt-64">
          {/* <h1>{mdx.frontmatter.title}</h1> */}
          <MDXProvider components={shortcodes}>
            <MDXRenderer embeddedImages={embeddedImagesByKey}>
              {mdx.body}
            </MDXRenderer>
          </MDXProvider>

          <div className="grid-cols-2 nextprev grid mt-auto mb-16 pt-32">
            <div className="justify-self-start">
              {previous ? (
                <>
                  <Link
                    to={`../${previous.frontmatter.slug}`}
                    className="text-2xl font-medium transXb inline-block"
                  >
                    {previous.frontmatter.title}
                  </Link>
                </>
              ) : null}
            </div>
            <div className="justify-self-end">
              {next ? (
                <>
                  <Link
                    to={`../${next.frontmatter.slug}`}
                    className="text-2xl font-medium transXf inline-block"
                  >
                    {next.frontmatter.title}
                  </Link>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        embeddedImages {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  }
`
