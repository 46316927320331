import React from "react"

export default function Para({ title, para }) {
  return (
    <div className="flex flex-col md:flex-row my-8 md:my-16 lg:my-24">
      <h2 className="font-medium pr-12 text-lg md:text-xl mb-1 md:mb-2 md:w-2/6">{title}</h2>
      <p className="text-base md:w-4/6 lg:w-4/6 lg:text-lg">{para}</p>
      <div className="md:w-1/6 lg:w-2/6"></div>
    </div>
  )
}
