import React from "react"

export default function Press({ url, title }) {
  return (
    <div className="">
      <a rel="noopener noreferrer" target="_blank" href={url} className="">
        <h3 className="font-normal md:text-base mb-1 text-gray-300 inline-block hover:text-white transition-all hover:transition-all">{title}</h3>
      </a>
    </div>
  )
}
